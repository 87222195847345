var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    {
      class: _vm.$vuetify.theme.light ? undefined : "grey lighten-3",
      attrs: { id: "pages" },
    },
    [
      _c(
        "v-responsive",
        {
          staticClass: "d-flex align-center",
          style: _vm.styles,
          attrs: { "min-height": "100vh" },
        },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }